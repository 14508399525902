import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import photo from "../images/kathy-tom.jpg"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <h1>Hi people</h1>
    <p>Hi, I'm a software developer. I like to make my computer do things.</p>

    <p>This is a picture of me and my wife Kathy in Maui.</p>

    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <img src={photo} alt="Kathy and Tom" />
    </div>

    <p>I also like to cook.</p>

    <Link to="/tech-stuff/">Technical fun</Link><br />
    <Link to="/pizza-crust/">Pizza crust</Link><br />
    <Link to="/deep-dish-pizza-crust/">Deep-dish pizza crust</Link><br />
    <Link to="/kathy-tomato-sauce/">Kathy's tomato sauce</Link><br />
  </Layout>
)

export default IndexPage
